import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import omit from 'lodash/fp/omit';

import { Reducer } from 'redux';

import * as actions from '../actions';
import { Action } from '../actions';
import * as selectors from '../adminRolesSelectors';
import * as appStates from '../appStates';
import type { ApiSuccess, State, UIRole } from 'adminRoles/types';
import type { Role, SortedRight, RightsCategory } from '@socrata/core-roles-api';
import { DomainRoleMask } from '@socrata/core-domain-roles-mask-api';
import { FeatureFlags } from 'common/feature_flags';

interface ToggleExpandedType {
  rightCategory: RightsCategory;
}

export default (): Reducer<State> => {
  function toggleExpanded(state: State, { rightCategory }: ToggleExpandedType): State {
    return selectors.updateRightsCategoryInState(
      state,
      rightCategory,
      selectors.toggleRightsCategoryExpanded
    );
  }

  function newCustomRole(state: State): State {
    return {
      ...selectors.clearNewRole(state),
      previousState: getOr(state, 'previousState', state),
      appState: appStates.NEW_CUSTOM_ROLE
    };
  }

  function editCustomRoleModalCancel(state: State): State {
    return {
      ...getOr(state, 'previousState', state),
      previousState: undefined
    };
  }

  function createNewRole(state: State): State {
    const appState = selectors.getAppState(state);
    const editingRole = selectors.getEditingRoleFromState(state);
    const templateRole = selectors.findRoleById(state, selectors.getEditingRoleTemplateIdFromState(state));
    const name = selectors.getRoleNameFromRole(editingRole).trim();

    if (appState === appStates.NEW_CUSTOM_ROLE) {
      const filteredRights = ['review_internal_approvals', 'review_public_approvals', 'configure_approvals'];
      const newRole: UIRole = {
        id: -1,
        name,
        isDefault: false,
        hasError: false,
        numberOfUsers: 0,
        rights: templateRole
          ? selectors.getRightsFromRole(templateRole).filter((right) => !filteredRights.includes(right))
          : []
      };

      return {
        ...selectors.clearNewRole(state),
        appState: appStates.EDIT_INDIVIDUAL_CUSTOM_ROLE,
        editingRole: newRole,
        scrollToNewRole: selectors.getIdFromRole(newRole),
        roles: state.roles.concat([newRole])
      };
    } else {
      return {
        ...selectors.clearNewRole(state),
        appState: appStates.DEFAULT,
        roles: state.roles.map((role) => (get('id', role) === get('id', editingRole) ? editingRole : role))
      };
    }
  }

  function editCustomRolesCancel(state: State): State {
    return {
      ...getOr(state, 'previousState', state),
      appState: appStates.DEFAULT
    };
  }

  function editCustomRolesStart(state: State): State {
    return {
      ...state,
      appState: appStates.EDIT_CUSTOM_ROLES,
      previousState: omit(['previousState'], state)
    };
  }

  function editCustomRolesEnd(state: State): State {
    return {
      ...omit(['previousState', 'editingRole'], state),
      appState: appStates.DEFAULT
    };
  }

  function changeNewRoleName(state: State, { name }: { name: string }): State {
    return {
      ...state,
      editingRole: {
        ...getOr(
          {
            id: -1,
            rights: [],
            isDefault: false,
            numberOfUsers: 0
          },
          'editingRole',
          state
        ),
        name,
        hasError: false,
        error: undefined
      }
    };
  }

  function changeNewRoleTemplate(state: State, { value }: { value: string }): State {
    return {
      ...state,
      editingRole: {
        ...getOr(
          {
            id: -1,
            name: '',
            rights: [],
            isDefault: false,
            numberOfUsers: 0
          },
          'editingRole',
          state
        ),
        template: value,
        hasError: false,
        error: undefined
      }
    };
  }

  function toggleRoleRight(state: State, { role, right }: { role: Role; right: SortedRight }): State {
    const updatedRole = selectors.roleHasRight(role, right)
      ? selectors.removeRightFromRole(role, right)
      : selectors.addRightToRole(role, right);
    return {
      ...state,
      roles: state.roles.map((r) => (r === role ? updatedRole : r))
    };
  }

  function toggleRoleRightCategoryValue(
    state: State,
    { role, rightCategory }: { role: Role; rightCategory: RightsCategory }
  ): State {
    const updatedRole = selectors.roleHasAllRightsInRightsCategory(role, rightCategory)
      ? selectors.removeAllRightsInCategoryFromRole(role, rightCategory)
      : selectors.addAllRightsInCategoryToRole(role, rightCategory);

    return {
      ...state,
      roles: state.roles.map((r) => (r === role ? updatedRole : r))
    };
  }

  function saveRolesStart(state: State): State {
    return {
      ...omit(['scrollToNewRole'], state),
      scrollToNewRole: null,
      appState: appStates.SAVING
    };
  }

  function saveRolesSuccess(state: State, { successes }: { successes: ApiSuccess[] }): State {
    const updatedRoles = selectors.getRolesFromState(state).map((r: Role) => {
      const successRole =
        find(({ role }) => selectors.getIdFromRole(role) === selectors.getIdFromRole(r), successes) || {};
      const savedRole = get('success', successRole);
      return savedRole ? { ...r, ...savedRole } : r;
    });

    return {
      ...editCustomRolesEnd(state),
      roles: updatedRoles
    };
  }

  function saveRolesFailure(state: State): State {
    return {
      ...state,
      appState: appStates.EDIT_INDIVIDUAL_CUSTOM_ROLE
    };
  }

  function deleteRoleStart(state: State, { role }: { role: Role }): State {
    return {
      ...state,
      previousState: state,
      deletingRole: role,
      appState: appStates.DELETE_INDIVIDUAL_CUSTOM_ROLE
    };
  }

  function deleteRoleEnd(state: State): State {
    const deletingRole = get('deletingRole', state);
    return {
      ...omit(['previousState', 'deletingRole'], state),
      roles: state.roles.filter((r) => r !== deletingRole),
      appState: appStates.DEFAULT
    };
  }

  function deleteRoleCancel(state: State): State {
    return {
      ...omit(['deletingRole'], state),
      appState: appStates.DEFAULT
    };
  }

  function startEditRole(state: State, { role }: { role: Role }): State {
    return {
      ...state,
      previousState: omit(['previousState'], state),
      editingRole: {
        ...role,
        hasError: false
      },
      appState: appStates.EDIT_INDIVIDUAL_CUSTOM_ROLE
    };
  }

  function handleRenameRole(state: State, { role }: { role: Role }): State {
    return {
      ...state,
      previousState: {
        ...omit(['previousState'], state)
      },
      editingRole: {
        ...role,
        hasError: false
      },
      appState: appStates.RENAME_INDIVIDUAL_CUSTOM_ROLE
    };
  }

  function startLoadData(state: State): State {
    return {
      ...state,
      appState: appStates.LOADING
    };
  }

  function endLoadData(
    state: State,
    { data }: { data: { rightCategories: RightsCategory[]; roles: Role[]; domainRoleMask: DomainRoleMask[] } }
  ): State {
    return {
      ...state,
      ...data,
      appState: appStates.DEFAULT
    };
  }

  function loadDataFailure(state: State): State {
    return {
      ...state,
      appState: appStates.LOAD_DATA_FAILURE
    };
  }

  function showNotification(state: State, payload: { [key: string]: string }): State {
    return {
      ...state,
      notification: {
        ...payload,
        showNotification: true
      }
    };
  }

  function showNotificationEnd(state: State): State {
    return {
      ...omit(['notification'], state),
      notification: {
        showNotification: false
      }
    };
  }

  function hoverRight(state: State, name: string): State {
    return {
      ...state,
      hovered: name
    };
  }

  function unhoverRight(state: State): State {
    return {
      ...omit(['hovered'], state)
    };
  }

  function assertUnreachable(ignored: never): never {
    throw new Error("Didn't expect to get here");
  }

  // @ts-ignore-error We are okay with disallowing undefined here.
  return (state: State, action: Action): State => {
    switch (action.type) {
      case actions.EDIT_CUSTOM_ROLES_CANCEL:
        return editCustomRolesCancel(state);
      case actions.EDIT_CUSTOM_ROLES_START:
        return editCustomRolesStart(state);
      case actions.EDIT_CUSTOM_ROLES_END:
        return editCustomRolesEnd(state);

      case actions.TOGGLE_EXPANDED:
        return toggleExpanded(state, action.payload);
      case actions.NEW_CUSTOM_ROLE:
        return newCustomRole(state);

      case actions.EDIT_CUSTOM_ROLE_MODAL_CANCEL:
        return editCustomRoleModalCancel(state);

      case actions.CREATE_NEW_ROLE_START:
        return createNewRole(state);
      case actions.CHANGE_NEW_ROLE_NAME:
        return changeNewRoleName(state, action.payload);
      case actions.CHANGE_NEW_ROLE_TEMPLATE:
        return changeNewRoleTemplate(state, action.payload);

      case actions.TOGGLE_ROLE_RIGHT_VALUE:
        return toggleRoleRight(state, action.payload);
      case actions.TOGGLE_ROLE_RIGHT_CATEGORY_VALUE:
        return toggleRoleRightCategoryValue(state, action.payload);

      case actions.SAVE_ROLES_START:
        return saveRolesStart(state);
      case actions.SAVE_ROLES_SUCCESS:
        return saveRolesSuccess(state, action.payload);
      case actions.SAVE_ROLES_FAILURE:
        return saveRolesFailure(state);

      case actions.DELETE_ROLE_START:
        return deleteRoleStart(state, action.payload);
      case actions.DELETE_ROLE_END:
        return deleteRoleEnd(state);
      case actions.DELETE_ROLE_CANCEL:
        return deleteRoleCancel(state);

      case actions.EDIT_ROLE_START:
        return startEditRole(state, action.payload);

      case actions.RENAME_ROLE:
        return handleRenameRole(state, action.payload);

      case actions.LOAD_DATA_START:
        return startLoadData(state);
      case actions.LOAD_DATA_SUCCESS:
        return endLoadData(state, action.payload);
      case actions.LOAD_DATA_FAILURE:
        return loadDataFailure(state);

      case actions.SHOW_NOTIFICATION:
        return showNotification(state, action.payload);
      case actions.SHOW_NOTIFICATION_END:
        return showNotificationEnd(state);

      case actions.HOVER_ROW:
        return hoverRight(state, action.payload.name);

      case actions.UNHOVER_ROW:
        return unhoverRight(state);

      // Saga-based actions
      case actions.DELETE_ROLE:
      case actions.EDIT_CUSTOM_ROLE_MODAL_SUBMIT:
      case actions.LOAD_DATA:
      case actions.SAVE_ROLES:
        return state;

      default:
        return state;
    }
  };
};
