import some from 'lodash/fp/some';
import partition from 'lodash/fp/partition';
import property from 'lodash/fp/property';
import { all, call, put, race, select, take, takeEvery, delay } from 'redux-saga/effects';

import {
  Role,
  UpdateRoleRequest,
  CreateRoleRequest,
  RoleUpdate,
  DeleteRoleRequest
} from '@socrata/core-roles-api';
import CoreApi from 'common/core/roles';

import CoreDomainRolesMaskApi from 'common/core/domainrolemask';

import * as Actions from './actions';
import * as Selectors from './adminRolesSelectors';
import { ApiResponse, ApiSuccess, ApiError } from './types';
import { filterRights, denyList } from './components/util/helpers';

const NOTIFICATION_DELAY = 5000;

// @ts-ignore
export function* loadData() {
  yield put(Actions.loadDataStart());

  try {
    const [rightCategories, roles, domainRoleMask] = yield all([
      call([CoreApi, CoreApi.getAllRights]),
      call([CoreApi, CoreApi.getAllRoles]),
      call([CoreDomainRolesMaskApi, CoreDomainRolesMaskApi.getDomainRolesMask])
    ]);
    let dataObj = { rightCategories, roles, domainRoleMask };
    if (denyList.length > 0) {
      dataObj = filterRights(dataObj);
    }
    yield put(Actions.loadDataSuccess(dataObj));
  } catch (error) {
    console.error('Error while loading data', error);
    yield put(Actions.showNotificationError('screens.admin.roles.alerts.load_data.error_html'));
    yield put(Actions.loadDataFailure(error));
  }
}

export function* showNotification() {
  yield delay(NOTIFICATION_DELAY);
  yield put(Actions.showNotificationEnd());
}

export function* handleRoleModal() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error
  const maxCharacterCount = yield select(Selectors.getMaxCharacterCountFromState);
  do {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const action = yield race({
      submit: take(Actions.EDIT_CUSTOM_ROLE_MODAL_SUBMIT),
      cancel: take(Actions.EDIT_CUSTOM_ROLE_MODAL_CANCEL)
    });

    if (action.submit) {
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error
      const role = yield select(Selectors.getEditingRoleFromState);
      const validatedRole = Selectors.validateRole(maxCharacterCount, role);
      if (!Selectors.roleHasError(validatedRole)) {
        return validatedRole;
      }
    } else {
      break;
    }
  } while (true);
}

export function* renameRole() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error
  const validatedRole = yield call(handleRoleModal);
  if (validatedRole) {
    yield put(Actions.saveRoles([validatedRole]));
  }
}

export function* createRole() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error
  const validatedRole = yield call(handleRoleModal);
  if (validatedRole) {
    yield put(Actions.createNewRoleStart());
  }
}

export const getRoleUpdate = (role: Role): RoleUpdate => {
  return {
    name: role.name,
    rights: role.rights
  };
};

export function* buildCreateRoleCall(role: Role) {
  try {
    const request: CreateRoleRequest = {
      roleUpdate: getRoleUpdate(role)
    };
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const success = yield call([CoreApi, CoreApi.createRole], request);
    return { role, success };
  } catch (error) {
    return { role, error };
  }
}

export function* buildUpdateRoleCall(role: Role) {
  try {
    const params: UpdateRoleRequest = {
      roleId: Selectors.getIdFromRole(role),
      roleUpdate: getRoleUpdate(role)
    };

    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const success = yield call([CoreApi, CoreApi.updateRole], params);
    return { role, success };
  } catch (error) {
    return { role, error };
  }
}

export const mapRoleToCall = (role: Role) =>
  call(Selectors.roleIsNew(role) ? buildCreateRoleCall : buildUpdateRoleCall, role);

interface ParsedError {
  message: string;
}

export function* saveRoles({ payload: { roles } }: Actions.SaveRolesAction) {
  yield put(Actions.saveRolesStart());
  const requestCalls = roles.map(mapRoleToCall);
  try {
    const responses: ApiResponse[] = yield all(requestCalls);
    const [successes, errors] = partition(property('success'), responses) as [ApiSuccess[], ApiError[]];
    if (errors.length === 0) {
      yield put(Actions.showNotificationSuccess('screens.admin.roles.alerts.save_roles.success_html'));
      yield put(Actions.saveRolesSuccess(successes));
    } else {
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error
      const mappedErrors = yield all(errors.map(({ error }): ParsedError => JSON.parse(error.message)));
      if (some((e) => e.message.startsWith('Role with name'), mappedErrors)) {
        yield put(Actions.showNotificationError('screens.admin.roles.alerts.save_roles.name_conflict'));
      } else {
        const htmlError = mappedErrors.map((e: ParsedError) => e.message).join('<br />');
        yield put(
          Actions.showNotificationError('screens.admin.roles.alerts.save_roles.error_html', {
            error: htmlError
          })
        );
      }
      yield put(Actions.saveRolesFailure());
    }
  } catch (error) {
    console.error('Unexpected error saving roles', error);
    yield put(
      Actions.showNotificationError('screens.admin.roles.alerts.save_roles.error_html', {
        error
      })
    );
    yield put(Actions.saveRolesFailure());
  }
}

export function* deleteRole({ payload: { role } }: Actions.DeleteRoleAction) {
  yield put(Actions.deleteRoleStart(role));
  try {
    const request: DeleteRoleRequest = {
      roleId: Selectors.getIdFromRole(role)
    };
    yield call([CoreApi, CoreApi.deleteRole], request);
    yield put(Actions.showNotificationSuccess('screens.admin.roles.alerts.delete_role.success_html'));
    yield put(Actions.deleteRoleEnd());
  } catch (error) {
    console.error('Error while deleting role', error);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const data = yield error.json();
    let message;
    if (data != null && data.code === 'ROLE.HAS_USERS') {
      message = 'screens.admin.roles.alerts.delete_role.has_users_html';
    } else {
      message = 'screens.admin.roles.alerts.delete_role.error_html';
    }
    yield put(Actions.showNotificationError(message));
    yield put(Actions.deleteRoleCancel());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(Actions.DELETE_ROLE, deleteRole),
    takeEvery(Actions.SAVE_ROLES, saveRoles),
    takeEvery(Actions.SHOW_NOTIFICATION, showNotification),
    takeEvery(Actions.NEW_CUSTOM_ROLE, createRole),
    takeEvery(Actions.RENAME_ROLE, renameRole),
    takeEvery(Actions.LOAD_DATA, loadData)
  ]);
}
