import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import stubTrue from 'lodash/fp/stubTrue';
import isNil from 'lodash/isNil';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import * as selectors from '../../adminRolesSelectors';
import { Cell, Column, Header } from '../util/Grid';
import RoleEditControl from './RoleEditControl';
import RoleRightCategory from './RoleRightCategory';

import type { State } from '../../types';
import type { RightsCategory, Role } from '@socrata/core-roles-api';
import { DomainRoleMask } from '@socrata/core-domain-roles-mask-api';

interface StateProps {
  editingRole: Role;
  formatInvitedUsers: (role: Role) => string;
  isEditCustomRolesAppState: boolean;
  isEditIndividualRoleAppState: boolean;
  rightCategories: RightsCategory[];
}

const mapStateToProps = (state: State): StateProps => {
  const invitedUsers = I18n.t('screens.admin.roles.index_page.grid.invited');
  return {
    formatInvitedUsers: (role: Role) => `${selectors.getNumberOfInvitedUsersFromRole(role)} ${invitedUsers}`,
    editingRole: selectors.getEditingRoleFromState(state),
    isEditCustomRolesAppState: selectors.isEditCustomRolesAppState(state),
    isEditIndividualRoleAppState: selectors.isEditIndividualRoleAppState(state),
    rightCategories: selectors.getRightCategoriesFromState(state)
  };
};

interface OwnProps {
  key?: string;
  role: Role;
  domainRoleMask: DomainRoleMask;
}

type Props = StateProps & OwnProps;

class RoleColumn extends Component<Props> {
  render() {
    const {
      editingRole,
      formatInvitedUsers,
      isEditCustomRolesAppState,
      isEditIndividualRoleAppState,
      rightCategories,
      role,
      domainRoleMask
    } = this.props;
    const editingThisColumn = selectors.getIdFromRole(role) === selectors.getIdFromRole(editingRole);
    const editingColumn = (isEditIndividualRoleAppState && editingThisColumn) || isEditCustomRolesAppState;

    const isDefault = selectors.roleIsDefault(role);
    const styleName = cond<boolean, string>([
      [constant(isDefault), constant('default-role-column')],
      [constant(isEditCustomRolesAppState), constant('edit-custom-role-column')],
      [() => isEditIndividualRoleAppState && editingThisColumn, constant('edit-custom-role-column')],
      [() => isEditIndividualRoleAppState && !editingThisColumn, constant('default-role-column')],
      [stubTrue, constant('custom-role-column')]
    ])(true);

    const roleId = selectors.getIdFromRole(role);
    const roleName = selectors.getRoleNameFromRole(role);
    const roleDisplayName = isDefault
      ? I18n.t(selectors.getRoleNameTranslationKeyPathFromRole(role))
      : roleName;

    const numberOfUsers = selectors.getNumberOfUsersFromRole(role);
    const numberOfInvitedUsers = selectors.getNumberOfInvitedUsersFromRole(role);
    const showNUmberOfInvitedUsers = !isNil(numberOfInvitedUsers) && numberOfInvitedUsers > 0;
    const editMode = isEditCustomRolesAppState || isEditIndividualRoleAppState;

    const hideRoleEditControl =
      isDefault || isEditCustomRolesAppState || (isEditIndividualRoleAppState && !editingThisColumn);
    return (
      <Column className={styleName}>
        <Header className="role-header-cell">
          <h6>{roleDisplayName}</h6>
          {hideRoleEditControl || <RoleEditControl role={role} />}
        </Header>
        {rightCategories.map((rightCategory) => (
          <RoleRightCategory
            key={`${roleName}_${selectors.getTranslationKeyFromRightsCategory(rightCategory)}`}
            rightCategory={rightCategory}
            role={role}
            roleName={roleName}
            editingColumn={editingColumn}
            isDefault={isDefault}
            domainRoleMask={domainRoleMask}
            editMode={editMode}
          />
        ))}
        <Cell className="role-footer-cell">
          <a href={`/admin/users?roleId=${roleId}`}>
            {I18n.t('screens.admin.roles.index_page.grid.user_count', {
              count: numberOfUsers
            })}
          </a>
          {showNUmberOfInvitedUsers && (
            <span>
              {' / '}
              <a href={`/admin/users/invited?roleId=${roleId}`}>{formatInvitedUsers(role)}</a>
            </span>
          )}
        </Cell>
      </Column>
    );
  }
}

export default connect(mapStateToProps)(RoleColumn);
